import { ModalConfirm } from "@/interfaces/notification";
import { Component, Prop, Vue } from "vue-property-decorator";
import Template from "./confirm.template.vue";

@Component({
  mixins: [Template]
})
export default class ConfirmModalComponent extends Vue {
  @Prop({ required: true })
  public modalData!: ModalConfirm;

  public accept() {
    this.$emit("resolve");
  }

  public cancel() {
    this.$emit("reject");
  }
}
