import { EventBus } from "@/internal";
import { localDateToUTCTime, UTCTimeToLocalDate } from "@/utils/date-fns.utils";
import { HelixDatePickerComponent } from "helix-vue-components";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { daysOfTheWeek, Range, rangeDefault } from "../../Definitions";
import Template from "./ValidRange.template.vue";

@Component({
  mixins: [Template],
  components: {
    HelixDatePickerComponent
  }
})
export default class ValidRange extends Vue {
  public daysOfTheWeek = [...daysOfTheWeek];
  public invalid = false;

  public startDateMenu = false;
  public endDateMenu = false;

  @Prop({ required: true })
  public incomingRange!: Range;
  @Prop()
  public limitingRange!: Range;
  @Prop({ default: true })
  public canDelete!: boolean;
  @Prop({ default: true })
  public dateFields!: boolean;

  public model: Range = { ...rangeDefault };
  public rangeDays: string[] = [];
  public allDays = false;

  public startTime: string | Date = "";
  public endTime: string | Date = "";

  public from: Date | null = null;
  public to: Date | null = null;

  public updateTime(model: string) {
    const time = model === "from" ? this.from : this.to;
    this.model[model] = localDateToUTCTime(time!);
  }

  public deleteSelf() {
    this.$emit("deleteRange");
  }

  @Watch("rangeDays")
  public updateRange(values: string[], oldValues: string[]) {
    this.invalid = false;
    Object.keys(this.model).forEach(key => {
      if (values.includes(key)) {
        this.model[key] = true;
      } else if (this.daysOfTheWeek.includes(key)) {
        this.model[key] = false;
      }
    });
    this.allDays = this.rangeDays.length === 7;
    if (values.length < oldValues.length) {
      this.$emit("updateRange", this.model);
    }
  }

  @Watch("model", { deep: true })
  public updateModel() {
    if (!isEqual(this.model, rangeDefault)) {
      this.$emit("updateRange", this.model);
    }
  }

  get icon() {
    if (this.allDays) {
      return "fas fa-check-square";
    }
    if (this.rangeDays.length > 0) {
      return "fas fa-minus-square";
    }
    return "far fa-square";
  }

  public selectAll() {
    this.allDays = !this.allDays;
    if (!this.allDays) {
      this.rangeDays = [];
    }
    if (this.allDays) {
      this.rangeDays = [...this.daysOfTheWeek];
    }
  }

  public setModel() {
    this.model = cloneDeep(this.incomingRange);
    if (this.model.from) {
      this.from = UTCTimeToLocalDate(this.model.from.split("+")[0]);
    }
    if (this.model.to) {
      this.to = UTCTimeToLocalDate(this.model.to.split("+")[0]);
    }
    if (this.model.start_date) {
      this.startTime = new Date(this.model.start_date);
    }
    if (this.model.end_date) {
      this.endTime = new Date(this.model.end_date);
    }
    let days = 0;
    Object.keys(this.model).forEach(key => {
      if (this.daysOfTheWeek.includes(key)) {
        if (this.model[key]) {
          days++;
          this.rangeDays.push(key);
        }
      }
    });
    if (days === 7) {
      this.allDays = true;
    }
  }

  public setDaysOfTheWeek() {
    const newSet: string[] = [];
    Object.keys(this.limitingRange).forEach(key => {
      if (this.daysOfTheWeek.includes(key)) {
        if (!!this.limitingRange[key]) {
          newSet.push(key);
        }
      }
    });

    this.daysOfTheWeek = !isEqual(this.limitingRange, rangeDefault)
      ? newSet
      : this.daysOfTheWeek;
  }

  public mounted() {
    if (this.incomingRange) {
      this.setModel();
    }
    if (this.limitingRange) {
      this.setDaysOfTheWeek();
    }
    EventBus.$on("requiredDay", () => {
      EventBus.$emit("notify", {
        color: "red",
        text: "discount_manager.select_a_day"
      });
      this.invalid = true;
    });
  }
}
