import { MemberLevel } from "@/interfaces/memberLevel";
import { Product } from "@/interfaces/product";

export interface AddDiscountForm {
  id?: number;
  name: string;
  code: string;
  active: boolean;
  scope: string;
  external_barcode: string;
  clear_other_line_item_discount: boolean;
  cumulative_eligible: boolean;
  apply_limit: boolean;
  apply_limit_value: number;
  post_tax: boolean;
  apply_limit_type: string;
  member_levels: MemberLevel[];
  valid_hours?: boolean;
  start_date: string;
  end_date: string;
  print_discount_on_receipt: boolean;
  print_discount_if_spent_amount: number;
  discount_description_to_be_print: string;
  apply_to_all_products: boolean | number;
  eligible_products?: Product[];
  type: string;
  type_attributes: TypeAttributes;
  main_range?: Range | null;
  ranges?: Range[];
  apply_member_levels?: boolean;
  apply_valid_hours?: boolean;
  auto_apply?: boolean;
}

export interface Range {
  id: number | null;
  from: string;
  to: string;
  start_date: string;
  end_date: string;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
  [key: string]: any;
}

export const rangeDefault: Range = {
  id: null,
  from: "",
  to: "",
  start_date: "",
  end_date: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};

export const daysOfTheWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday"
];

export interface TypeAttributes {
  rate: number;
  type: string;
  lapse?: number;
  lapse_days?: number;
  lapse_type?: string;
  buy_amount?: number;
  get_amount?: number;
  buy_one_products?: Product[];
  get_one_products?: Product[];
}

export const MockBaseModel: AddDiscountForm = {
  name: "",
  code: "",
  scope: "LINE_ITEM",
  active: true,
  external_barcode: "",
  clear_other_line_item_discount: false,
  cumulative_eligible: false,
  post_tax: false,
  apply_limit: false,
  apply_limit_value: 0,
  apply_limit_type: "",
  member_levels: [],
  valid_hours: false,
  start_date: "",
  end_date: "",
  print_discount_on_receipt: false,
  print_discount_if_spent_amount: 0,
  discount_description_to_be_print: "",
  type: "PERCENTAGE_DISCOUNT_TYPE",
  type_attributes: {
    rate: 0,
    type: "PERCENTAGE",
    lapse: 0,
    lapse_days: 0,
    lapse_type: "",
    buy_amount: 0,
    get_amount: 0,
    buy_one_products: [],
    get_one_products: []
  },
  apply_to_all_products: 1,
  eligible_products: [],
  ranges: [],
  main_range: { ...rangeDefault },
  apply_member_levels: false,
  apply_valid_hours: false
};
