import { Location } from "@/interfaces/location";
import {
  LoyaltyPoint,
  LoyaltyProgram,
  MockLoyaltyPoint
} from "@/interfaces/loyaltyProgram";
import { MemberLevel } from "@/interfaces/memberLevel";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { memberLevelService } from "@/services/memberLevel.service";
import { messagesService } from "@/services/messages.service";
import { PageNavAction } from "@/types/types";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import LoyaltyPoints from "./loyaltyPoints/LoyaltyPoints.component";
import Template from "./LoyaltyProgramForm.template.vue";
import LoyaltyPrograms from "./loyaltyPrograms/LoyaltyPrograms.component";

@Component({
  mixins: [Template],
  components: {
    LoyaltyPoints,
    LoyaltyPrograms
  }
})
export default class LoyaltyProgramForm extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public tab = 1;
  public loyaltyPoint: LoyaltyPoint = cloneDeep(MockLoyaltyPoint);
  public loyaltyPrograms: LoyaltyProgram[] = [];
  public memberLevels: MemberLevel[] = [];
  public initialMemberLevels: MemberLevel[] = [];
  public loading = false;

  public async saveLoyaltyPointsConfig() {
    this.loading = true;
    try {
      if (this.loyaltyPoint) {
        if (
          this.loyaltyPoint.redemption_value_per_points === null ||
          this.loyaltyPoint.redemption_value_dollars === null
        ) {
          messagesService.showMessage(
            "fal fa-exclamation-triangle",
            String(
              this.$t(
                "batch_transfer_manager_module.messages.missing_form_data"
              )
            ),
            "error"
          );
        } else if (
          this.loyaltyPoint.enable_loyalty_points &&
          (this.loyaltyPoint.redemption_value_per_points! < 1 ||
            this.loyaltyPoint.redemption_value_dollars! < 1)
        ) {
          messagesService.showMessage(
            "fal fa-exclamation-triangle",
            String(this.$t("negative_field_error")),
            "error"
          );
        } else {
          const loyaltyPoint = await loyaltyProgramService.saveModel(
            this.loyaltyPoint,
            "points"
          );

          if (
            !isEqual(
              this.loyaltyPoint.member_levels,
              this.initialMemberLevels
            ) ||
            !this.loyaltyPoint.member_levels_enabled
          ) {
            const memberLevels = loyaltyProgramService.getMemberLevels(
              this.loyaltyPoint.member_levels,
              this.loyaltyPoint.member_levels_enabled
            );
            await loyaltyProgramService.syncMemberLevels(
              "points",
              loyaltyPoint.id,
              memberLevels
            );
          }
          this.getLoyaltyPoints();
          messagesService.renderSuccessMessage(
            "loyalty_programs.loyalty_program_config_saved"
          );
        }
      }
    } catch (e) {
      // @ts-ignore
      messagesService.renderErrorMessage(e.error);
    } finally {
      this.loading = false;
    }
  }

  public async getLoyaltyPrograms(loyaltyPrograms: LoyaltyProgram[]) {
    this.loyaltyPrograms = loyaltyPrograms;
  }

  public async getLoyaltyPoints() {
    const response = await loyaltyProgramService.getLoyaltyPoints();
    if (response) {
      this.loyaltyPoint = response;
      this.initialMemberLevels = this.loyaltyPoint.member_levels;
      this.loyaltyPoint.member_levels_enabled = !!this.loyaltyPoint
        .member_levels.length;
    }
  }

  public cancel() {
    this.$router.push({ name: "retail-options" });
  }

  protected async created() {
    this.setPageNav({
      title: "loyalty_programs.title",
      isLoading: () => this.loading,
      rightActions: {
        generalActions: () => [
          {
            icon: "fal fa-check",
            action: this.saveLoyaltyPointsConfig,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          },
          {
            icon: "fal fa-times",
            action: this.cancel,
            vuetifyProps: () => ({
              loading: this.loading,
              fab: true,
              small: true
            })
          }
        ]
      }
    });
    this.memberLevels = await memberLevelService.get();
    this.getLoyaltyPoints();
  }
}
