import ValidRange from "@/components/retail/discountManager/discountManagerAdd/BasicInfo/validRange/ValidRange.component";
import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { currencyFilter } from "@/filters/currency.filter";
import {
  LoyaltyProgram,
  LoyaltyProgramTier,
  MockLoyaltyProgramTier
} from "@/interfaces/loyaltyProgram";
import { Product } from "@/interfaces/product";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { messagesService } from "@/services/messages.service";
import { productService } from "@/services/product.service";
import cloneDeep from "lodash/cloneDeep";
import debounce from "lodash/debounce";
import pick from "lodash/pick";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Template from "./TierForm.template.vue";

@Component({
  mixins: [Template],
  components: {
    ValidRange
  },
  filters: {
    currencyFilter
  }
})
export default class TierForm extends Vue {
  public programTiers: LoyaltyProgramTier[] = [];
  public tiers: LoyaltyProgramTier[] = [];

  @Prop({ required: true })
  public loyaltyProgram!: LoyaltyProgram;
  public products: Product[] = [];
  public searchProductField: string | null = null;
  public isLoading: boolean = false;
  public isFormValid: boolean = false;
  public defaultProductsList: Product[] = [];
  public savingForm = false;
  public debounceProductSearch = debounce(
    async (context, productName: string) => {
      if (!context.model.product) {
        await this.setDefaultProductList();
      }
      context.isLoading = true;
      context.products =
        (productName && (await productService.findByName(productName))) || [];
      if (!context.products.length) {
        context.products = cloneDeep(context.defaultProductsList);
      }
      context.isLoading = false;
    },
    500
  );

  public model: LoyaltyProgramTier | null = null;
  public itemToDelete: LoyaltyProgramTier | null = null;
  public deleteIndex = -1;
  public modelIndex = -1;
  public loading = false;

  @Watch("searchProductField")
  public async searchData(productName: string) {
    this.debounceProductSearch(this, productName);
  }

  public async setDefaultProductList() {
    if (!this.defaultProductsList.length) {
      this.defaultProductsList = await productService.get({});
    }
  }

  public async addNew() {
    this.setDefaultProductList().then(() => {
      this.products = cloneDeep(this.defaultProductsList);
    });
    this.model = cloneDeep(MockLoyaltyProgramTier);
    this.model.loyalty_program_id = this.loyaltyProgram.id;
    this.modelIndex = -1;
  }
  public ruleMinPrice(value: number) {
    return value > 0 || this.$t("requiere_min").toString();
  }

  public async editLoyaltyProgramTier(
    loyaltyProgramTier: LoyaltyProgramTier,
    index: number
  ) {
    this.model = cloneDeep(loyaltyProgramTier);
    this.products = [loyaltyProgramTier.product as Product];
    this.modelIndex = index;
  }

  public openDelete(loyaltyProgramTier: LoyaltyProgramTier, index: number) {
    this.$modals
      .load(
        ConfirmModalComponent,
        {
          size: "small"
        },
        {
          modalData: {
            title: "loyalty_programs.delete_program_tier_title",
            body: this.$t("loyalty_programs.delete_program_tier_confirm", {
              name: loyaltyProgramTier.name
            }),
            icon: "fal fa-exclamation-triangle"
          }
        }
      )
      .then(() => {
        this.loading = true;
        this.$emit("updateTier", {
          currentTier: loyaltyProgramTier,
          index,
          deleted: true
        });
        this.itemToDelete = null;
        this.deleteIndex = -1;
        this.loading = false;
      })
      .catch(() => false);
  }

  public async save() {
    this.savingForm = true;
    const valid = await this.$validator.validate();
    if (valid) {
      if (this.loyaltyProgram.id) {
        const currentTier = await loyaltyProgramService.saveTier(
          this.model!,
          "program_tiers"
        );
        if (currentTier) {
          const itemIndex = this.loyaltyProgram.program_tiers!.findIndex(
            t => t.id === currentTier.id
          );
          const index =
            itemIndex < 0
              ? this.loyaltyProgram.program_tiers!.length
              : itemIndex;
          this.$emit("updateTier", { currentTier, index });
          messagesService.renderSuccessMessage("loyalty_programs.saved");
        }
      } else {
        this.$emit("updateTier", {
          currentTier: {
            ...this.model,
            sku: this.model!.product!.sku,
            product: pick(this.model!.product, ["sku", "name"])
          }
        });
      }
      this.model = null;
      this.modelIndex = -1;
    }
    this.savingForm = false;
  }

  public cancel() {
    this.modelIndex = -1;
    this.model = null;
  }
}
