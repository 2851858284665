import ValidRange from "@/components/retail/discountManager/discountManagerAdd/BasicInfo/validRange/ValidRange.component";
import {
  Range,
  rangeDefault
} from "@/components/retail/discountManager/discountManagerAdd/Definitions";
import { fnsSimpleDate, fnsTime } from "@/filters/date-fns.filter";
import {
  LoyaltyBonusPoint,
  LoyaltyPoint,
  MockLoyaltyBonusPoint
} from "@/interfaces/loyaltyProgram";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DeletePointsModalComponent } from "../DeletePointsModal/DeletePointsModal.component";
import Template from "./BonusPointForm.template.vue";

@Component({
  mixins: [Template],
  components: {
    ValidRange
  },
  filters: { fnsSimpleDate, fnsTime }
})
export default class BonusPointForm extends Vue {
  @Prop({ required: true })
  public bonusPoints!: LoyaltyBonusPoint[];
  @Prop({ required: true })
  public loyaltyPoint!: LoyaltyPoint;
  public saving = false;
  public model: LoyaltyBonusPoint | null = null;
  public itemToDelete: LoyaltyBonusPoint | null = null;
  public modelIndex = -1;
  public oldRange: Range = rangeDefault;

  public addNew() {
    this.model = cloneDeep(MockLoyaltyBonusPoint);
    this.model.loyalty_point_id = this.loyaltyPoint.id!;
  }
  public async save() {
    try {
      if (this.model) {
        this.saving = true;
        // i do this, because i spread the range into the bonusPoint
        const modelData = {
          id: this.model.id,
          time_frame_name: this.model.time_frame_name,
          points_multiplier: this.model.points_multiplier,
          online_orders_only: this.model.online_orders_only,
          schedule: this.model.schedule,
          loyalty_point_id: this.model.loyalty_point_id
        };
        const bonusPoint = { ...this.model.range, ...modelData };
        await loyaltyProgramService.saveModel(bonusPoint, "bonus_points");
        this.$emit("reloadBonus");
        messagesService.renderSuccessMessage("bonus_points_saved");
        this.saving = false;
        this.model = null;
        this.modelIndex = -1;
      }
    } catch (e) {
      this.saving = false;
      messagesService.renderErrorMessage(e);
    }
  }

  public editBonusPoint(bonusPoint: LoyaltyBonusPoint, index: number) {
    this.model = cloneDeep(bonusPoint);
    this.modelIndex = index;
  }

  public openDelete(bonusPoint: LoyaltyBonusPoint) {
    this.itemToDelete = bonusPoint;
    this.$modals
      .load(
        DeletePointsModalComponent,
        {
          size: "fit",
          positionY: "center"
        },
        {
          itemToDelete: bonusPoint
        }
      )
      .then(() => {
        this.confirmDeletion();
      })
      .catch(() => {
        // No action on close.
      });
  }

  public updateRange(modelRange: Range) {
    if (this.model) {
      this.model.range = modelRange;
    }
  }

  public async confirmDeletion() {
    try {
      if (this.itemToDelete) {
        await loyaltyProgramService.deleteModel(
          this.itemToDelete,
          "bonus_points"
        );
        this.$emit("reloadBonus");
        messagesService.renderSuccessMessage("bonus_points_deleted");
        this.itemToDelete = null;
      }
    } catch (e) {
      messagesService.renderErrorMessage(e);
    }
  }

  public cancel() {
    this.modelIndex = -1;
    this.model = null;
  }
}
