import ConfirmModalComponent from "@/components/sharedComponents/confirm/confirm.component";
import { Location } from "@/interfaces/location";
import {
  LoyaltyProgram,
  LoyaltyProgramTier,
  MockLoyaltyProgram
} from "@/interfaces/loyaltyProgram";
import { MemberLevel } from "@/interfaces/memberLevel";
import { i18n } from "@/plugins/i18n";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import { messagesService } from "@/services/messages.service";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import last from "lodash/last";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Template from "./LoyaltyPrograms.template.vue";
import TierForm from "./tierForm/TierForm.component";

@Component({
  mixins: [Template],
  components: {
    TierForm
  }
})
export default class LoyaltyPrograms extends Vue {
  @Getter("currentLocation", { namespace: "AuthModule" })
  public currentLocation!: Location;
  @Prop({ required: true })
  public loyaltyPrograms!: LoyaltyProgram[];
  @Prop({ required: true })
  public memberLevels!: MemberLevel[];
  public initialMemberLevels: MemberLevel[] = [];
  public loadingData = true;
  public basedOnOptions = [
    {
      label: i18n.t("AMOUNT_SPENT"),
      value: "AMOUNT_SPENT"
    },
    {
      label: i18n.t("NUMBER_OF_VISITS"),
      value: "NUMBER_OF_VISITS"
    },
    {
      label: i18n.t("LOYALTY_POINTS"),
      value: "LOYALTY_POINTS"
    }
  ];

  public model: LoyaltyProgram | null = null;
  public itemToDelete: LoyaltyProgram | null = null;
  public modelIndex = -1;
  public saveTier: boolean = false;

  public editLoyaltyProgram(
    loyaltyProgram: LoyaltyProgram,
    index: number,
    memberLevelsOnly?: boolean
  ) {
    this.model = cloneDeep(loyaltyProgram);
    this.initialMemberLevels = this.model.member_levels;
    this.model.member_levels_only =
      memberLevelsOnly || !!this.model.member_levels.length;
    this.modelIndex = index;
  }

  public addNew() {
    this.model = cloneDeep(MockLoyaltyProgram);
    this.initialMemberLevels = [];
    this.model.location_id = this.currentLocation.id!;
    this.modelIndex = this.loyaltyPrograms.length;
  }

  public get currentLoyaltyPrograms() {
    return this.model && !this.model!.id
      ? [...this.loyaltyPrograms, this.model]
      : this.loyaltyPrograms;
  }

  public openDelete(loyaltyProgram: LoyaltyProgram) {
    this.itemToDelete = loyaltyProgram;
    this.$modals
      .load(
        ConfirmModalComponent,
        {
          size: "small"
        },
        {
          modalData: {
            title: "loyalty_programs.delete_loyalty_program",
            body: this.$t("loyalty_programs.delete_loyalty_program_confirm", {
              name: this.itemToDelete.name
            }),
            icon: "fal fa-exclamation-triangle"
          }
        }
      )
      .then(() => this.confirmDeletion(loyaltyProgram))
      .catch(() => false);
  }

  public async confirmDeletion(loyaltyProgram: LoyaltyProgram) {
    this.loadingData = true;
    const wasDeleted = await loyaltyProgramService.deleteModel(
      loyaltyProgram,
      "programs"
    );
    if (wasDeleted) {
      messagesService.renderSuccessMessage("loyalty_program_deleted");
      await this.getLoyaltyPrograms();
      this.cancel();
    }
    this.loadingData = false;
  }

  public async save() {
    this.loadingData = true;
    if (this.model) {
      try {
        const loyaltyProgram = await loyaltyProgramService.saveModel(
          this.model,
          "programs"
        );
        if (loyaltyProgram) {
          if (
            !isEqual(this.model.member_levels, this.initialMemberLevels) ||
            !this.model.member_levels_only
          ) {
            const memberLevels = loyaltyProgramService.getMemberLevels(
              this.model.member_levels,
              this.model.member_levels_only
            );
            await loyaltyProgramService.syncMemberLevels(
              "programs",
              loyaltyProgram.id,
              memberLevels
            );
          }
          await this.getLoyaltyPrograms();
          this.cancel();
          this.loadingData = false;
          messagesService.renderSuccessMessage("loyalty_programs.saved");
          return true;
        }
        this.loadingData = false;
      } catch (e) {
        this.loadingData = false;
        messagesService.renderErrorMessage(e);
      }
    }
    return false;
  }
  public cancel() {
    this.modelIndex = -1;
    this.model = null;
  }

  public async onDeleteTier(tier: LoyaltyProgramTier, index: number) {
    this.loadingData = true;
    const resp = await loyaltyProgramService.deleteModel(tier, "program_tiers");
    if (resp) {
      messagesService.renderSuccessMessage("loyalty_program_tiers_deleted");
      this.model!.program_tiers!.splice(index, 1);
    }
    this.loadingData = false;
  }

  public async updateTier(tier: {
    currentTier: LoyaltyProgramTier;
    index: number;
    deleted: boolean;
  }) {
    if (tier.deleted) {
      this.onDeleteTier(tier.currentTier, tier.index);
    } else {
      if (!this.model!.id) {
        this.model!.program_tiers!.push(tier.currentTier);
        const memberLevelsOnly = this.model!.member_levels_only;
        this.save().then(resp => {
          if (resp) {
            this.editLoyaltyProgram(
              last(this.currentLoyaltyPrograms)!,
              this.currentLoyaltyPrograms.length - 1,
              memberLevelsOnly
            );
          } else {
            this.model!.program_tiers = [];
          }
        });
      } else {
        this.model!.program_tiers![tier.index] = tier.currentTier;
      }
    }
  }

  public async getLoyaltyPrograms() {
    const response:
      | LoyaltyProgram[]
      | null = await loyaltyProgramService.getLoyaltyPrograms();
    if (response) {
      this.$emit("reloadPrograms", response);
    }
  }

  protected async mounted() {
    this.loadingData = true;
    if (!this.loyaltyPrograms.length) {
      await this.getLoyaltyPrograms();
    }
    this.loadingData = false;
  }
}
