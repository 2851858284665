import { SearchQuery } from "@/interfaces/httpQuery";
import {
  FinalPoints,
  LoyaltyBonusPoint,
  LoyaltyModifyResponse,
  LoyaltyPoint,
  LoyaltyProgram,
  LoyaltyProgramTier,
  UpdatedPoints
} from "@/interfaces/loyaltyProgram";
import { MemberLevel } from "@/interfaces/memberLevel";
import { AxiosResponse } from "axios";
import { TablePagination } from "helix-vue-components";
import forEach from "lodash/forEach";
import pick from "lodash/pick";
import { Vue } from "vue-property-decorator";
import HttpService from "./http.service";
import { messagesService } from "./messages.service";

class LoyaltyProgramsService extends HttpService {
  protected mainUri: string = "loyalty";
  protected searchableField: SearchQuery[] = [
    {
      field: "name",
      type: "contains"
    }
  ];

  public async getLoyaltyPrograms(): Promise<LoyaltyProgram[] | null> {
    this.uri = this.mainUri + "/programs";
    this.query = {
      embed: "programTiers.product,memberLevels"
    };

    try {
      const response = await super.get();
      return response.data.data;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async getLoyaltyPoints(): Promise<LoyaltyPoint> {
    this.uri = this.mainUri + "/points";
    this.query = {
      embed: "memberLevels"
    };
    const response = await super.get();
    // we grab the first (should be the only config that comes up);
    return response.data.data[0];
  }

  public async syncMemberLevels(
    model: string,
    id: number,
    memberLevels: number[]
  ) {
    const uri = this.uri;
    this.uri = `loyalty/${model}/${id}/member_levels/sync`;
    const response = await super.post({ member_levels: memberLevels });
    this.uri = uri;
    return response.data;
  }

  public async getTransactionLog(
    customerId: number | string,
    pagination: TablePagination
  ) {
    this.uri = "loyalty/transactions";
    const query = {
      customer_id: customerId,
      type: "LOYALTY_POINTS",
      embed: "location,user",
      sort: "-id",
      page: pagination.currentPage,
      per_page: pagination.itemsPerPage
    };
    const response = await super.get(query);
    return response.data;
  }

  public async getBonusPoints(
    loyaltyPointId: number
  ): Promise<LoyaltyBonusPoint[]> {
    this.uri = this.mainUri + "/bonus_points";
    this.query = {
      loyalty_point_id: loyaltyPointId
    };
    const response = await super.get();
    return response.data.data;
  }
  public async saveTier(
    model: LoyaltyProgramTier,
    table: string
  ): Promise<any> {
    try {
      const currentId = model.id ? `/${model.id}` : "";
      const response: AxiosResponse = await Vue.axios({
        method: model.id ? "PUT" : "POST",
        url: `${this.mainUri}/${table}${currentId}`,
        data: {
          ...model,
          sku: model.product!.sku,
          product: pick(model.product, ["sku", "name"])
        }
      });
      return response.data.data || response.data || response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async saveModel(model: any, table: string): Promise<any> {
    this.uri = this.mainUri + `/${table}`;
    let response;
    try {
      if (model.id) {
        model._method = "PUT";
        response = await super.put(model, model);
      } else {
        response = await super.post(model);
      }
      return response.data || response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public async deleteModel(model: any, table: string): Promise<any> {
    this.uri = this.mainUri + `/${table}`;
    let response;
    try {
      if (table === "programs") {
        response = await super.deleteForce(model);
      } else {
        response = await super.delete(model);
      }
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }

  public getMemberLevels(modelLevels: MemberLevel[], flag: boolean) {
    const memberLevels: number[] = [];
    if (flag) {
      forEach(modelLevels, (memberLevel: MemberLevel) => {
        memberLevels.push(memberLevel.id!);
      });
    }
    return memberLevels;
  }

  public async modifyLoyaltyPoints(
    customerUID: number | null | undefined,
    points: FinalPoints
  ): Promise<LoyaltyModifyResponse | null> {
    this.uri = `/store/customers/${customerUID}/rewards`;
    let response;
    try {
      response = await super.put({}, points);
      return response;
    } catch (e) {
      messagesService.renderErrorMessage(e);
      return null;
    }
  }
}

export const loyaltyProgramService = new LoyaltyProgramsService();
