import { LoyaltyBonusPoint, LoyaltyPoint } from "@/interfaces/loyaltyProgram";
import { MemberLevel } from "@/interfaces/memberLevel";
import { EventBus } from "@/internal";
import { loyaltyProgramService } from "@/services/loyaltyPrograms.service";
import forEach from "lodash/forEach";
import { Component, Prop, Vue } from "vue-property-decorator";
import BonusPointForm from "./bonusPointForm/BonusPointForm.component";
import Template from "./LoyaltyPoints.template.vue";

@Component({
  mixins: [Template],
  components: {
    BonusPointForm
  }
})
export default class LoyaltyPoints extends Vue {
  @Prop({ required: true })
  public loyaltyPoint!: LoyaltyPoint;
  @Prop({ required: true })
  public memberLevels!: MemberLevel[];
  public errorMessage = {
    redemption_value_dollars: false,
    redemption_value_per_points: false,
    points_to_dollars_spent: false
  };

  public bonusPoints: LoyaltyBonusPoint[] = [];
  public test = 1;
  public getRules(type?: string) {
    if (
      this.errorMessage.redemption_value_dollars &&
      type === "redemption_value_dollars"
    ) {
      return this.$t("no_greater_value_than");
    }
    if (
      this.errorMessage.redemption_value_per_points &&
      type === "redemption_value_per_points"
    ) {
      return this.$t("no_greater_value");
    }
    if (
      this.errorMessage.points_to_dollars_spent &&
      type === "points_to_dollars_spent"
    ) {
      return this.$t("no_greater_value");
    }
  }
  public validateNumber(value?: string, type?: string) {
    if (type === "redemption_value_dollars") {
      if (value && !/^[0-9]{0,6}(?:\.[0-9]{1,2})?$/.test(value)) {
        this.test += 1;
        this.$nextTick(() => {
          (this.$refs.redemption_value_dollars as HTMLElement).focus();
        });
        setTimeout(() => {
          this.errorMessage.redemption_value_dollars = false;
        }, 2500);
        this.errorMessage.redemption_value_dollars = true;
      } else {
        this.errorMessage.redemption_value_dollars = false;
        this.loyaltyPoint.redemption_value_dollars = Number(value);
      }
    }
    if (type === "redemption_value_per_points") {
      if (value && !/^[0-9]{1,6}?$/.test(value)) {
        this.test += 1;
        this.$nextTick(() => {
          (this.$refs.redemption_value_per_points as HTMLElement).focus();
        });
        setTimeout(() => {
          this.errorMessage.redemption_value_per_points = false;
        }, 2500);
        this.errorMessage.redemption_value_per_points = true;
      } else {
        this.errorMessage.redemption_value_per_points = false;
        this.loyaltyPoint.redemption_value_per_points = Number(value);
      }
    }
    if (type === "points_to_dollars_spent") {
      if (value && !/^[0-9]{1,6}?$/.test(value)) {
        this.test += 1;
        this.$nextTick(() => {
          (this.$refs.points_to_dollars_spent as HTMLElement).focus();
        });
        setTimeout(() => {
          this.errorMessage.points_to_dollars_spent = false;
        }, 2500);
        this.errorMessage.points_to_dollars_spent = true;
      } else {
        this.errorMessage.points_to_dollars_spent = false;
        this.loyaltyPoint.points_to_dollars_spent = Number(value);
      }
    }
  }
  public async getBonusPoints() {
    const response = await loyaltyProgramService.getBonusPoints(
      this.loyaltyPoint.id!
    );
    forEach(response, (bonusPoint: LoyaltyBonusPoint) => {
      bonusPoint.range = { ...bonusPoint };
    });
    this.bonusPoints = response;
    EventBus.$emit("reloadedBonus");
  }
  public requiredRule(value: number) {
    if (!this.loyaltyPoint.enable_loyalty_points) {
      return value >= 0 || this.$t("positive_zero_error-0");
    } else {
      return value >= 1 || this.$t("positive_zero_error");
    }
  }
  public async reloadBonusPoints() {
    this.getBonusPoints();
  }

  protected async mounted() {
    this.getBonusPoints();
    if (
      !this.loyaltyPoint.redemption_value_dollars &&
      !this.loyaltyPoint.redemption_value_per_points &&
      !this.loyaltyPoint.points_to_dollars_spent
    ) {
      this.loyaltyPoint.redemption_value_dollars = 1;
      this.loyaltyPoint.redemption_value_per_points = 1;
      this.loyaltyPoint.points_to_dollars_spent = 0;
    }
  }
}
