import { LoyaltyBonusPoint } from "@/interfaces/loyaltyProgram";
import Component from "vue-class-component";
import { Prop, Vue } from "vue-property-decorator";
import Template from "./DeletePointsModal.template.vue";

@Component({
  mixins: [Template]
})
export class DeletePointsModalComponent extends Vue {
  @Prop({ required: true }) public itemToDelete!: LoyaltyBonusPoint;

  public confirmDeletion() {
    this.$emit("resolve");
  }

  public cancel() {
    this.$emit("reject");
  }
}
