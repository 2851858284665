import {
  Range,
  rangeDefault
} from "@/components/retail/discountManager/discountManagerAdd/Definitions";
import { MemberLevel } from "./memberLevel";

export interface LoyaltyProgram {
  id: number | null;
  location_id: number | null;
  name: string;
  program_based_on: string;
  member_levels_only: boolean;
  member_levels: MemberLevel[];
  program_tiers?: LoyaltyProgramTier[];
  total_points?: { LOYALTY_POINTS: number };
}

export interface LoyaltyPoint {
  id: number | null;
  location_id: number | null;
  enforce_loyalty_points: boolean;
  enable_loyalty_points: number;
  member_levels_enabled: boolean;
  member_levels: MemberLevel[];
  post_tax_loyalty_points: number;
  points_to_dollars_spent: number;
  redemption_value_dollars: number | null;
  redemption_value_per_points: number | null;
  customer_referrals_enabled: number;
  customer_referrals_points_earned_per_dollar: number;
  share_points_across_locations: number;
}

export interface LoyaltyBonusPoint extends Range {
  id: number | null;
  time_frame_name: string;
  points_multiplier: number;
  online_orders_only: boolean;
  schedule: string;
  loyalty_point_id: number | null;
  range?: Range;
}

export interface LoyaltyProgramTier {
  id: number | null;
  apply?: boolean;
  name?: string;
  remaining?: number;
  loyalty_program_id: number | null;
  sku: string | null;
  price: number;
  product?: {
    sku?: string;
    name?: string;
  };
  based_on_value: number;
  max_redeemable: number;
  max_quantity_you_can_carry?: number;
  cumulative_eligible: number;
}

export const MockLoyaltyProgram = {
  id: null,
  location_id: null,
  name: "",
  program_based_on: "NUMBER_OF_VISITS",
  member_levels_only: false,
  member_levels: [],
  program_tiers: []
};

export const MockLoyaltyPoint = {
  id: null,
  location_id: null,
  enable_loyalty_points: 1,
  enforce_loyalty_points: false,
  member_levels_enabled: false,
  member_levels: [],
  post_tax_loyalty_points: 1,
  points_to_dollars_spent: 0,
  redemption_value_dollars: 0,
  redemption_value_per_points: 0,
  customer_referrals_enabled: 1,
  customer_referrals_points_earned_per_dollar: 0,
  share_points_across_locations: 1
};

export const MockLoyaltyBonusPoint = {
  id: null,
  time_frame_name: "",
  points_multiplier: 0,
  online_orders_only: false,
  schedule: "DATE_RANGE",
  loyalty_point_id: null,
  range: rangeDefault,
  from: "",
  to: "",
  start_date: "",
  end_date: "",
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false
};

export const MockLoyaltyProgramTier = {
  id: null,
  loyalty_program_id: null,
  sku: null,
  price: 0,
  based_on_value: 0,
  max_redeemable: 0,
  cumulative_eligible: 1
};

export interface LoyaltyProgramModalProduct {
  rewardMax: number | null;
  rewardPrice: number | null;
  loyaltyProgramTierId: number | null;
  position: number | null;
}

export interface LoyaltyModifyResponse {
  customer_id: number | string | null;
  total_points: number | null;
  location_id: number | null;
}

export interface FinalPoints {
  points: number;
}

export interface Program {
  account_id?: number | null;
  auto_apply?: number | null;
  created_at?: string;
  id?: number | null;
  location_id?: number | null;
  loyalty_point_id?: number | null;
  name?: string;
  program_based_on?: string;
  program_tiers?: LoyaltyProgramTier[];
  updated_at?: string;
}

export const MockProgram = {
  account_id: null,
  auto_apply: null,
  created_at: "",
  id: null,
  location_id: null,
  loyalty_point_id: null,
  name: "",
  program_based_on: "",
  program_tiers: [MockLoyaltyProgramTier],
  updated_at: ""
};

export interface LoyaltyData {
  customerUID?: number | null;
  points?: {
    loyalty_point?: LoyaltyPoint;
    loyalty_redemption?: {
      loyalty_points?: number | null;
      redeem_value?: number | null;
    };
    programs?: Program[];
    total_points?: {
      AMOUNT_SPENT?: number | null;
      LOYALTY_POINTS?: number | null;
      NUMBER_OF_VISITS?: number | null;
    };
  };
}

export const MockLoyaltyData: LoyaltyData = {
  customerUID: null,
  points: {
    loyalty_point: MockLoyaltyPoint,
    loyalty_redemption: { loyalty_points: null, redeem_value: null },
    programs: [MockProgram],
    total_points: {
      AMOUNT_SPENT: null,
      LOYALTY_POINTS: null,
      NUMBER_OF_VISITS: null
    }
  }
};

export const defaultProgram: LoyaltyProgramModalProduct = {
  rewardMax: null,
  rewardPrice: null,
  loyaltyProgramTierId: null,
  position: null
};

export interface UpdatedPoints {
  customer_id: string;
  total_points: number;
  location_id: number;
}
